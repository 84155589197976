/* eslint-disable @typescript-eslint/no-unused-vars */

import { Inject, NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { SnackbarService, environment } from '@tuacar-workspace/core';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import { HttpHeaders } from '@angular/common/http';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/angular';

export function createApollo(
  httpLink: HttpLink,
  snackbarService: SnackbarService,
) {
  const uri = environment.graphqlUri;

  const errorHandlerLink = onError((err, ...param) => {
    const { graphQLErrors, networkError: HttpErrorResponse } = err;
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path, extensions }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${extensions}`,
        );
        if (extensions && extensions['message']) {
          snackbarService.Error((extensions['message'] as string).toString());
          Sentry.captureMessage((extensions['message'] as string).toString());
        } else {
          snackbarService.Error(message);
          Sentry.captureMessage(message);
        }
      });
    }
    if (err.networkError) {
      console.log(`[Network error]: ${err.networkError}`);
      // snackbarService.Error(networkError.message);
      // Sentry.captureException(networkError);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const netErr = err.networkError as any;
      for (const er of netErr.error.errors) {
        // Add scoped report details and send to Sentry
        Sentry.withScope((scope) => {
          // Annotate whether failing operation was query/mutation/subscription
          scope.setTag('kind', err.operation.operationName);
          // Log query and variables as extras
          // (make sure to strip out sensitive data!)
          scope.setExtra('query', err.operation.query);
          scope.setExtra('variables', err.operation.variables);
          scope.setExtra('message', er.message);

          Sentry.captureException(er);
        });
      }
    }
  });

  return {
    link: errorHandlerLink.concat(
      httpLink.create({
        uri,
        extractFiles: (body) => extractFiles(body, isExtractableFile),
        headers: new HttpHeaders({
          'GraphQL-Preflight': 1,
        }),
      }),
    ),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, SnackbarService],
    },
  ],
})
export class GraphQLModule {}
