const backendUri = 'https://manager-api.tuacar.it';
const production = true;
const version = '3.1.5';

export const environment = {
  production: production,
  version: version,
  backendBaseUri: backendUri,
  graphqlUri: backendUri + '/graphql',
  uploadPictureUri: backendUri + '/car/picture',
  crmUrl: 'https://crm.tuacar.it',
  webSiteUrl: 'https://tua-car.it',
  damBaseUrl: 'https://ik.imagekit.io/tuacar',
  mobilePictureUploadUrl: 'https://manager-mobile.tuacar.it/',
  wasabiUrl: 's3.eu-central-2.wasabisys.com',
  wasabiBucket: 'tuacar',
  wasabiAccessKey: 'WZV0K8EUU3PK9M4V9F7H',
  wasabiSecretKey: 'TmLJ3ElbZLFpkDGf9yuaWQbc9ZkPc0uKNykzGDVC',
  auth0Domain: 'auth.tuacar.it',
  auth0ClientId: 'BDK6PYL5Aw2mJ4hcvKnmwsX95HBL1es5',
  env: 'production',
  sentryDsn:
    'https://6ae940e86cd3fcd68d3c680029f4a831@o4505641054502912.ingest.sentry.io/4505872684875776',
  ablyKey: '1Nw9OQ.qXZMgA:0rMklrDaHBqsvaw7vaZ7hlau58I0SkjUkZvhot2ZQEY',
};
